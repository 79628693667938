<template>
  <div>
    <b-field
      :type="type"
    >
      <b-input
        v-model="searchText"
        icon="search"
        :placeholder="placeholder"
        @input="setFilteredItems"
      />
    </b-field>
    <b-field
      :message="inMessage"
      :type="type"
    >
      <b-select
        multiple
        expanded
        v-model="iselectedItems"
        @input="updateSelected"
        @dblclick.native="updateDoubleClick"
      >
        <option
          v-for="(item,index) in filteredItems"
          :key="index"
          :value="item"
          style="overflow: scroll; text-overflow: ellipsis;"
        >
          {{ item }}
        </option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'SearchableList',
  props: {
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    selectedItems: {
      type: Array,
      default: function () {
        return []
      }
    },
    placeholder: {
      type: String,
      default: 'Value'
    },
    onlyOneSelection: {
      type: Boolean,
      default: false
    },
    type: {
      type: Object,
      default: null
    },
    message: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      searchText: null,
      filteredItems: this.items,
      iselectedItems: []
    }
  },
  computed: {
    inMessage: function () {
      if (this.message) {
        if (this.message.length > 0) {
          return this.message
        } else {
          return 'Showing ' + this.filteredItems.length + ' item(s)'
        }
      } else {
        return 'Showing ' + this.filteredItems.length + ' item(s)'
      }
    }
  },
  methods: {
    resetInput () {
      this.searchText = null
    },
    getFilteredItems () {
      return this.filteredItems
    },
    updateDoubleClick () {
      this.$emit('updateDoubleClick', this.iselectedItems)
    },
    updateSelected () {
      if (this.onlyOneSelection) {
        this.$emit('update:selectedItems', [this.iselectedItems[this.iselectedItems.length - 1]])
      } else {
        this.$emit('update:selectedItems', this.iselectedItems)
      }
    },
    setFilteredItems (text) {
      this.filteredItems = this.items.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    }
  },
  watch: {
    selectedItems: function () {
      this.iselectedItems = this.selectedItems
    },
    items: function () {
      this.filteredItems = this.items
    }
  }
}
</script>

<style>
.select select[multiple] {
  height: 300px !important;
}
</style>
